
export default {
  name: 'BreadcrumbCategory',
  props: {
    breadcrumb: {
      type: Array,
      default() {}
    }
  },
  components: {
    Wrapper: () => import('@/components/Structure/Wrapper')
  }
}
